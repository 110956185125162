<template>
  <div class="mx-4 py-4 md:mx-6 md:py-6">
    <div class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
      <div class="border-b border-gray-200 pb-5">
        <div class="flex flex-wrap items-center justify-between sm:flex-nowrap">
          <h3 class="text-lg font-bold leading-6 text-gray-900">Laporan</h3>
        </div>
        <!-- <p class="mt-2 max-w-4xl text-sm text-gray-500">Informasi faktur (auto generate dari Stockist).</p> -->
      </div>
      <!-- Replace with your content -->
      <div class="py-2">
        <Datatable
          :total="getOrders.meta.page.total"
          :perPage="getOrders.meta.page.perPage"
          :currentPage="getOrders.meta.page.currentPage"
          @pagechanged="onPageChangeOrder"
        >
          <template v-slot:thead>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                ></th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  No. Faktur
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Kode Stockist
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Nama Pemilik
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Kode Gudang
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Wilayah Harga
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Saldo Stockist
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Total Penjualan
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody>
              <tr
                class="cursor-pointer bg-white hover:bg-green-100"
                v-for="(data, dataIdx) in getOrders.data"
                :key="data.id"
                :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
              >
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  <input
                    v-model="checkedOrders"
                    aria-describedby="comments-description"
                    :value="data.id"
                    name="comments"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                >
                  {{ data.attributes.code }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{
                    getRelationships(
                      data.relationships['destination-office'].data.id
                    ).attributes.code
                  }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{
                    getRelationships(
                      data.relationships['destination-office'].data.id
                    ).attributes.name
                  }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{ data.attributes.origin_warehouse_code }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{ data.attributes.area_name }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  -
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{ data.attributes.final_price }}
                </td>
              </tr>
            </tbody>
          </template>
        </Datatable>
      </div>
    </div>

    <div class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
      <div class="flex w-full flex-row gap-x-4 gap-y-2 pb-4">
        <div class="w-full">
          <div class="field-inset-default relative">
            <label
              for="kode_gudang"
              class="block text-xs font-bold text-gray-700"
            >
              Nama Laporan
            </label>
            <input
              v-model="report.name"
              type="text"
              name="saldo_awal"
              id="saldo_awal"
              class="input-inset-select"
              placeholder="ex: Laporan pembelian barang"
            />
          </div>
        </div>
        <div class="relative w-full">
          <div class="field-inset-default relative">
            <label
              for="kode_gudang"
              class="block text-xs font-bold text-gray-700"
            >
              Deskripsi Laporan
            </label>
            <input
              v-model="report.description"
              type="text"
              name="saldo_awal"
              id="saldo_awal"
              class="input-inset-select"
              placeholder="ex: beli 5 barang dibayar lunas"
            />
          </div>
        </div>
      </div>

      <div class="flex justify-end">
        <button
          v-if="checkedOrders.length > 0"
          @click="sendReport"
          type="button"
          class="focus:outline-none inline-flex items-center gap-2 rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
        >
          Simpan Laporan
        </button>
        <button
          v-else
          type="button"
          disabled
          class="inline-flex items-center gap-2 rounded-md border border-transparent bg-gray-300 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm"
        >
          Simpan Laporan
        </button>
      </div>
    </div>

    <!-- Modal Succes -->

    <transition name="fade">
      <div
        v-if="success"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <modal @modal-action="successModal" link="/kasir/laporan">
          <template v-slot:svg>
            <div
              class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                class="h-6 w-6 text-green-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          </template>
          <template v-slot:heading>
            <h3
              class="text-lg font-bold leading-6 text-gray-900"
              id="modal-title"
            >
              Data berhasil disimpan
            </h3>
          </template>
        </modal>
      </div>
    </transition>

    <!-- end of modal success -->

    <!-- Modal error -->
    <transition name="fade">
      <div
        v-if="failed"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <modal @modal-action="failedModal" link="/penjualan/faktur-sc">
          <template v-slot:svg>
            <div
              class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-red-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </template>
          <template v-slot:heading>
            <h3
              class="text-lg font-bold leading-6 text-gray-900"
              id="modal-title"
            >
              {{ getError[0].detail }}
            </h3>
          </template>
        </modal>
      </div>
    </transition>
    <!-- end of modal error -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import Datatable from '@/components/base/Datatable';
import Modal from '@/components/Modal.vue';
export default {
  name: 'TambahPenjualan',
  components: {
    Datatable,
    Modal,
  },

  data() {
    return {
      success: false,
      failed: false,
      report: {
        name: null,
        description: null,
      },
      checkedOrders: [],
    };
  },

  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
    }),
  },

  methods: {
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
      createOrderBundle: 'order_bundles/createOrderBundle',
    }),
    successModal() {
      this.success = !this.success;
      this.clearData();
    },
    failedModal() {
      this.failed = !this.failed;
      this.clearData();
    },
    detailModal() {
      this.openDetail = !this.openDetail;
      this.clearData();
    },
    onPageChangeOrder(page) {
      this.fetchOrders({
        order_type: 'purchase',
        pageNumber: page,
        pageSize: 5,
        is_valid_for_payment: true,
        has_bundle: false,
        ascending: true,
      });
    },
    getDataDetails(params) {
      let dataDetail = [];
      params.map((param) => {
        let data = {
          type: 'orders',
          id: param,
        };
        dataDetail.push(data);
      });
      return dataDetail;
    },
    sendReport() {
      const payload = {
        data: {
          type: 'order-bundles',
          attributes: {
            name: this.report.name,
            description: this.report.description,
          },
          relationships: {
            orders: {
              data: this.getDataDetails(this.checkedOrders),
            },
          },
        },
      };
      this.createOrderBundle(payload).then((response) => {
        if (response) {
          this.success = true;
        } else {
          this.failed = false;
        }
      });
    },
    getRelationships(id) {
      let data = this.getOrders.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
  },
  created() {
    this.fetchOrders({
      order_type: 'purchase',
      is_valid_for_payment: true,
      has_bundle: false,
      pageNumber: 1,
      pageSize: 5,
      ascending: true,
    });
  },
};
</script>
